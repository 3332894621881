import React, {useContext} from 'react';
import './ExtraServices.scss';
import Button from '../../components/ui/Button/Button';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useOutletContext} from 'react-router-dom';
import i1 from './images/01.svg';
import i2 from './images/02.svg';
import i3 from './images/03.svg';
import i4 from './images/04.svg';
import i5 from './images/05.svg';
import Headline from '../../components/ui/Headline/Headline';
import Calculator from '../../components/Calculator/Calculator';

const ExtraServices: React.FC<any> = (props) => {
    const {i18n} = useContext(i18nContext);
    const {setContactUSModalProps} = useOutletContext<any>();
    const handleOpenFeedbackModal = () => {
        setContactUSModalProps({isOpen: true});
    }

    return (<>
        <div className="extra-services">
            <Headline>Extra services</Headline>

            <section className="extra-card">
                <div className="extra-card__left-block">
                    <img className="extra-card__img" src={i1} alt={i18n.t('extraServices.insurance.title')}/>
                    <h3 className="extra-card__title">{i18n.t('extraServices.insurance.title')}</h3>
                </div>
                <div className="extra-card__right-block">
                    <div className="extra-card__list-title">{i18n.t('extraServices.insurance.description2')}</div>
                    <ul className="extra-card__list">
                        <li className="extra-card__list-item">{i18n.t('extraServices.insurance.list.1')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.insurance.list.2')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.insurance.list.3')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.insurance.list.4')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.insurance.list.5')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.insurance.list.6')}</li>
                    </ul>
                    <Button
                        className="extra-card__action button"
                        onClick={() => handleOpenFeedbackModal()}>
                        {i18n.t('extraServices.insurance.button')}
                    </Button>
                </div>
            </section>

            <section className="extra-card">
                <div className="extra-card__left-block">
                    <img className="extra-card__img" src={i2} alt={i18n.t('extraServices.obtaining.title')}/>
                    <h3 className="extra-card__title">{i18n.t('extraServices.obtaining.title')}</h3>
                </div>
                <div className="extra-card__right-block">
                    <div className="extra-card__list-title">{i18n.t('extraServices.obtaining.description2')}</div>
                    <ul className="extra-card__list">
                        <li className="extra-card__list-item">{i18n.t('extraServices.obtaining.list.1')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.obtaining.list.2')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.obtaining.list.3')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.obtaining.list.4')}</li>
                    </ul>
                    <Button
                        className="extra-card__action button"
                        onClick={() => handleOpenFeedbackModal()}>
                        {i18n.t('extraServices.obtaining.button')}
                    </Button>
                </div>
            </section>

            <section className="extra-card">
                <div className="extra-card__left-block">
                    <img className="extra-card__img" src={i3} alt={i18n.t('extraServices.transaction.title')}/>
                    <h3 className="extra-card__title">{i18n.t('extraServices.transaction.title')}</h3>
                </div>
                <div className="extra-card__right-block">
                    <div className="extra-card__list-title">{i18n.t('extraServices.transaction.description2')}</div>
                    <ul className="extra-card__list">
                        <li className="extra-card__list-item">{i18n.t('extraServices.transaction.list.1')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.transaction.list.2')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.transaction.list.3')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.transaction.list.4')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.transaction.list.5')}</li>
                    </ul>
                    <Button
                        className="extra-card__action button"
                        onClick={() => handleOpenFeedbackModal()}>
                        {i18n.t('extraServices.transaction.button')}
                    </Button>
                </div>
            </section>

            <section className="extra-card">
                <div className="extra-card__left-block">
                    <img className="extra-card__img" src={i4} alt={i18n.t('extraServices.notary.title')}/>
                    <h3 className="extra-card__title">{i18n.t('extraServices.notary.title')}</h3>
                </div>
                <div className="extra-card__right-block">
                    <div className="extra-card__list-title">{i18n.t('extraServices.notary.description2')}</div>
                    <ul className="extra-card__list">
                        <li className="extra-card__list-item">{i18n.t('extraServices.notary.list.1')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.notary.list.2')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.notary.list.3')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.notary.list.4')}</li>
                    </ul>
                    <Button
                        className="extra-card__action button"
                        onClick={() => handleOpenFeedbackModal()}>
                        {i18n.t('extraServices.notary.button')}
                    </Button>
                </div>
            </section>

            <section className="extra-card">
                <div className="extra-card__left-block">
                    <img className="extra-card__img" src={i5} alt={i18n.t('extraServices.registration.title')}/>
                    <h3 className="extra-card__title">{i18n.t('extraServices.registration.title')}</h3>
                </div>
                <div className="extra-card__right-block">
                    <div className="extra-card__list-title">{i18n.t('extraServices.registration.description2')}</div>
                    <ul className="extra-card__list">
                        <li className="extra-card__list-item">{i18n.t('extraServices.registration.list.1')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.registration.list.2')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.registration.list.3')}</li>
                        <li className="extra-card__list-item">{i18n.t('extraServices.registration.list.4')}</li>
                    </ul>
                    <Button
                        className="extra-card__action button"
                        onClick={() => handleOpenFeedbackModal()}>
                        {i18n.t('extraServices.registration.button')}
                    </Button>
                </div>
            </section>
        </div>

        <Calculator />
    </>);
};

export default ExtraServices;
