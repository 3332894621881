import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Root from './routes/Root/Root';
import {createHashRouter, Navigate, RouterProvider} from 'react-router-dom';
import {store} from './store/store';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import {Provider as MobxProvider} from 'mobx-react';
import Home from './routes/Home/Home';
import I18nProvider from './components/i18n/I18nProvider';
import PrivacyPolicy from './routes/PrivacyPolicy/PrivacyPolicy';
import ExtraServices from './routes/ExtraServices/ExtraServices';
import Catalog from './routes/Catalog/Catalog';
import CatalogCardDetails from './routes/CatalogCardDetails/CatalogCardDetails';
import AboutUs from './routes/AboutUs/AboutUs';
import {APIProvider} from '@vis.gl/react-google-maps';
// @ts-ignore
import {GMAPS_API_KEY} from './configs/google.ts';
import Sell from './routes/Sell/Sell';
import TermsOfUse from './routes/TermsOfUse/TermsOfUse';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createHashRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "/privacy",
                element: <PrivacyPolicy />
            },
            {
                path: "/extras",
                element: <ExtraServices />
            },
            {
                path: "/sell",
                element: <Sell />
            },
            {
                path: "/buy",
                element: <Catalog />
            },
            {
                path: "/buy/realEstate/:id",
                element: <CatalogCardDetails />
            },
            {
                path: "/about",
                element: <AboutUs />
            },
            {
                path: "/terms-of-use",
                element: <TermsOfUse />
            },
        ],
    },
    {
        path:"*",
        element:<Navigate to="/" replace />
    }
]);

root.render(
  <React.StrictMode>
      <MobxProvider store={store}>
          <APIProvider apiKey={GMAPS_API_KEY} region={'ES'} language={'en'}>
              <I18nProvider>
                  <ErrorBoundary>
                      <RouterProvider router={router} />
                  </ErrorBoundary>
              </I18nProvider>
          </APIProvider>
      </MobxProvider>
  </React.StrictMode>
);

reportWebVitals();
