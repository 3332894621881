import React, {useContext, useState} from 'react';
import FieldText from '../ui/FieldText/FieldText';
import FieldPhone from '../ui/FieldPhone/FieldPhone';
import Textarea from '../ui/Textarea/Textarea';
import Checkbox from '../ui/Checkbox/Checkbox';
import Button from '../ui/Button/Button';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {i18nContext} from '../i18n/I18nProvider';
import {observer} from 'mobx-react';
import {Lead, leadStoreContext} from '../../store/components/LeadStore';

type ContactFormProps = {
    onClose: (isSuccess: boolean) => void;
    type: string;
}

const ContactForm: React.FC<ContactFormProps> = observer((props) => {
    const {i18n} = useContext(i18nContext);
    const leadStoreCtx = useContext(leadStoreContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {handleSubmit, trigger, setValue} = methods;
    const [isRequest, setRequest] = useState(false);
    const [isChecked, setChecked] = useState(false);

    const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        leadStoreCtx.merge({[fieldName]: event.target.value});
        if (fieldName === 'phone') {
            methods.setValue('phone2', event.target.value);
            methods.trigger('phone2').then(() => {});
        }
    }
    const handleChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setValue('privacy', event.target.checked)
        trigger('privacy').then(() => {});
    }
    const onSubmit: SubmitHandler<Inputs> = () => {
        const data: Lead = JSON.parse(JSON.stringify(leadStoreCtx.get()));
        let key: string;
        for (key in data) {
            // @ts-ignore
            if (data[key] === '') {
                // @ts-ignore
                data[key] = null;
            }
        }
        data.type = props.type;
        setRequest(true);
        leadStoreCtx.create(data)
            .then((result) => {
                if (result) {
                    props.onClose(true);
                    leadStoreCtx.clear();
                    setChecked(false);
                }
            }).finally(() => setRequest(false))
    }
    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content">
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <FieldText
                            placeholder={i18n.t('feedbackModal.fullNamePlaceholder')}
                            value={leadStoreCtx.lead.fullName ?? ''}
                            registerProps={{name: 'fullName', options: {required: true, onChange: handleChangeFieldText}}}
                        />
                    </div>
                </div>
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <FieldText
                            placeholder={i18n.t('feedbackModal.emailPlaceholder')}
                            value={leadStoreCtx.lead.email ?? ''}
                            registerProps={{name: 'email', options: {required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, onChange: handleChangeFieldText}}}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="hidden" {...methods.register('phone2', {required: true, onChange: handleChangeFieldText})}/>
                        <FieldPhone
                            type="tel"
                            className={`field-phone ${methods && methods.formState.errors && methods.formState.errors.phone2 ? 'field-phone--invalid' : ''}`}
                            placeholder={'_________'}
                            value={leadStoreCtx.lead.phone ?? ''}
                            onChange={handleChangeFieldText}
                            name={'phone'}
                            maxLength={9}
                            phoneCode={leadStoreCtx.lead.phoneCode ?? ''}
                            onChangePhoneCode={(phoneCode) => leadStoreCtx.merge({phoneCode})}
                        />
                    </div>
                </div>
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Textarea
                            name="description"
                            value={leadStoreCtx.lead.description ?? ''}
                            onChange={handleChangeFieldText}
                            placeholder={i18n.t('feedbackModal.descriptionPlaceholder')}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-actions">
                <div className="row center-xs center-sm center-md center-lg center-xl between-xs between-sm between-md between-lg between-xl middle-xs middle-sm middle-md middle-lg middle-xl">
                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                        <Checkbox
                            onChange={handleChangePrivacy}
                            registerProps={{name: 'privacy', options: {required: true, onChange: handleChangePrivacy}}}
                            checked={isChecked}>
                            {i18n.t('feedbackModal.checkbox')}
                        </Checkbox>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                        <Button disabled={isRequest} className="button" type="submit">{i18n.t('feedbackModal.button')}</Button>
                    </div>
                </div>
            </div>
        </form>
    </FormProvider>)
})

type Inputs = {
    privacy: boolean;
    fullName: string,
    email: string,
    phone: string,
    phone2: string,
    description: string;
};

export default ContactForm;
