import React, {useContext, useEffect, useState} from 'react';
import './Home.scss';
import banner from './images/banner-background.jpg';
import {RealEstate, realEstateStoreContext} from '../../store/components/RealEstateStore';
import Button from '../../components/ui/Button/Button';
import {observer} from 'mobx-react';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useNavigate, useOutletContext} from 'react-router-dom';
import Searcher2 from './components/Searcher2/Searcher2';
import RealEstateCard from '../Catalog/components/RealEstateCard/RealEstateCard';
import Calculator from '../../components/Calculator/Calculator';

const Home = observer(() => {
    const navigate = useNavigate();
    const {i18n} = useContext(i18nContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const {setSellModalProps} = useOutletContext<any>();
    const [realEstates, setRealEstates] = useState<RealEstate[]>([] as RealEstate[]);

    const handleOpenEvaluate = () => {
        window.scrollTo(0, 0);
        setSellModalProps({isOpen: true})
    }

    const handleNavigateToCatalog = () => {
        navigate(`/buy?realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
    }

    useEffect(() => {
        realEstateStoreCtx.loadBestRealEstates().then((realEstates) => setRealEstates(realEstates));
    }, [realEstateStoreCtx]);

    return (<div className="home">
        <section className="home__banner">
            <div className="home__banner-scroller"></div>
            <div className="home__banner-image">
                <img src={banner} alt="domus banner"/>
                <h1 className="home__seo">{i18n.t('buy.seoHeader')}</h1>
            </div>
            <div className="home__banner-text">
                <h4>
                    <p>{i18n.t('buy.banner.descriptionTop')}</p>
                </h4>
                <Searcher2 />
            </div>
            <div className="home__banner-gradient"></div>
        </section>
        <section className="home__company-workflow">
            <div className="workflow-row__section">
                <h2 className="workflow-row__name">{i18n.t('buy.companyWorkflow.header.sell')}</h2>
                <div className="workflow-row-container">
                    <div className="workflow-row">
                        <div className="workflow-row__counter">
                            01
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.sell.1.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter">
                            02
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.sell.2.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter">
                            03
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.sell.3.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter">
                            04
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.sell.4.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter">
                            05
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.sell.5.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter">
                            06
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.sell.6.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter">
                            07
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.sell.7.title')}
                        </div>
                    </div>
                </div>
                <Button className="button workflow-row__action" onClick={handleOpenEvaluate}>{i18n.t('buy.companyWorkflow.sell.button')}</Button>
            </div>
            <div className="workflow-row__separator"></div>
            <div className="workflow-row__section">
                <h2 className="workflow-row__name">{i18n.t('buy.companyWorkflow.header.buy')}</h2>
                <div className="workflow-row-container">
                    <div className="workflow-row">
                        <div className="workflow-row__counter workflow-row__counter--transparent">
                            01
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.buy.1.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter workflow-row__counter--transparent">
                            02
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.buy.2.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter workflow-row__counter--transparent">
                            03
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.buy.3.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter workflow-row__counter--transparent">
                            04
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.buy.4.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter workflow-row__counter--transparent">
                            05
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.buy.5.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter workflow-row__counter--transparent">
                            06
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.buy.6.title')}
                        </div>
                    </div>
                    <div className="workflow-row">
                        <div className="workflow-row__counter workflow-row__counter--transparent">
                            07
                        </div>
                        <div className="workflow-row__title">
                            {i18n.t('buy.companyWorkflow.buy.7.title')}
                        </div>
                    </div>
                </div>
                <Button className="button workflow-row__action" onClick={handleNavigateToCatalog}>{i18n.t('buy.companyWorkflow.buy.button')}</Button>
            </div>
        </section>
        {
            realEstates.length > 0 && <section className="home__best-offers">
                <h2>{i18n.t('buy.bestOffers.title')}</h2>
                <div className="home__best-offers-cards">
                    {realEstates.map((realEstate: RealEstate) => <RealEstateCard realEstate={realEstate} key={realEstate.id}/>)}
                </div>
                <div className="home__best-offers-action">
                    <Button onClick={handleNavigateToCatalog}>
                        {i18n.t('buy.bestOffers.button')}
                    </Button>
                </div>
            </section>
        }
        <Calculator />
    </div>)
});

export default Home;
