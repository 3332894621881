import React from 'react';
import {RealEstate} from '../../../../store/components/RealEstateStore';
import './RealEstateCard.scss';
import {Link} from 'react-router-dom';
import {currencyFormatter} from '../../../../utils/functions';

type RealEstateCardProps = {
    realEstate: RealEstate;
}

const RealEstateCard: React.FC<RealEstateCardProps> = ({realEstate}) => {
    return (<Link to={`/buy/realEstate/${realEstate.id}`} className="real-estate__card">
        <div className="real-estate__card-preview">
            {/*@ts-ignore*/}
            {realEstate.previews && realEstate.previews[0] ? <img src={realEstate.previews[0].imageUrl} alt={realEstate.realEstateMarketingName}/> :
                <svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.139 155.139">
                    <g>
                        <g>
                            <path d="M125.967,51.533V20.314h-16.862V38.06L77.569,12.814L0,74.869h21.361v67.455h112.416v-67.45h21.361L125.967,51.533z M125.925,134.472H97.546V82.37H57.58v52.103H29.202V71.146l48.356-38.689l48.355,38.689v63.326H125.925z"/>
                        </g>
                    </g>
                </svg>
            }
        </div>
        <div className="real-estate__card-details">
            <div>
                <div className="real-estate__card-marketing-address">
                    {realEstate.realEstateMarketingName}
                </div>
                <div className="real-estate__card-presentation-address">
                    <i className="icon-local"/>
                    {realEstate.presentationManualAddress}
                </div>
            </div>
            <div className="real-estate__card-description">
                <div className="real-estate__card-description-metrics">
                    {
                        realEstate.bedroomCount &&
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-bed"/> {realEstate.bedroomCount}
                        </div>
                    }
                    {
                        realEstate.bathroomCount &&
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-Bath"/> {realEstate.bathroomCount}
                        </div>
                    }
                    {
                        realEstate.livingArea &&
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-sq-all"/> {realEstate.livingArea} m2
                        </div>
                    }
                    {
                        realEstate.plotArea &&
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-sq-live"/> {realEstate.plotArea} m2
                        </div>
                    }
                </div>
                <div className="real-estate__card-description-price">
                    {currencyFormatter(realEstate.price || 0, true)}
                </div>
            </div>
        </div>
    </Link>)
}

export default RealEstateCard;
