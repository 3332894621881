import React, {useContext, useEffect, useState} from 'react';
import './Root.scss';
import {observer} from 'mobx-react';
import {Outlet, useLocation} from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import SuccessModal from './components/SuccessModal/SuccessModal';
import ContactUSModal from './components/ContactUSModal/ContactUSModal';
import {leadStoreContext} from '../../store/components/LeadStore';
import {storeContext} from '../../store/store';
import SellModal from './components/SellModal/SellModal';
import BuyModal from './components/BuyModal/BuyModal';

const Root = observer(() => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const storeCtx = useContext(storeContext);
    const leadStateStoreCtx = useContext(leadStoreContext);
    const [sellModalProps, setSellModalProps] = useState({isOpen: false});
    const [buyModalProps, setBuyModalProps] = useState({isOpen: false});
    const [contactUSModalProps, setContactUSModalProps] = useState({isOpen: false, type: 'buyer'});
    const [successModalProps, setSuccessModalProps] = useState({isOpen: false});

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (window.location.pathname === '/' && params.get('buy') === 'true') {
            setTimeout(() => setBuyModalProps({isOpen: true}), 250);
        }
        if (window.location.pathname === '/' && params.get('evaluate') === 'true') {
            setTimeout(() => setSellModalProps({isOpen: true}), 250);
        }
    }, []);
    const handleOpenEvaluate = () => setSellModalProps({isOpen: true});
    const handleOpenFeedback = (type?: string) => setContactUSModalProps({isOpen: true, type: 'buyer'});
    const handleCloseLeadModal = (isSuccess: boolean) => {
        leadStateStoreCtx.clear();
        setSellModalProps({isOpen: false});
        setBuyModalProps({isOpen: false});
        if (isSuccess) {
            setSuccessModalProps({isOpen: true});
        }
    }
    const handleSuccessModal = () => setSuccessModalProps({isOpen: false});
    const handleCloseContactUSModal = (isSuccess: boolean) => {
        leadStateStoreCtx.clear();
        setContactUSModalProps({isOpen: false, type: 'buyer'});
        if (isSuccess) {
            setSuccessModalProps({isOpen: true});
        }
    }

    if (storeCtx.isLoading) {
        return null;
    }

    return (
        <>
            {sellModalProps.isOpen && <SellModal onClose={handleCloseLeadModal}/>}
            {buyModalProps.isOpen && <BuyModal onClose={handleCloseLeadModal}/>}
            {contactUSModalProps.isOpen && <ContactUSModal onClose={handleCloseContactUSModal} type={contactUSModalProps.type}/>}
            {successModalProps.isOpen && <SuccessModal onClose={handleSuccessModal}/>}
            <Header onOpenEvaluate={handleOpenEvaluate} onOpenFeedback={handleOpenFeedback}/>
            <div className="content">
                <Outlet context={{setSellModalProps, setBuyModalProps, setContactUSModalProps, setSuccessModalProps}}/>
            </div>
            <Footer onOpenEvaluate={handleOpenEvaluate} onOpenFeedback={handleOpenFeedback}/>
        </>
    );
});

export default Root;
