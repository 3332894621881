import React, {useContext, useEffect} from 'react';
import './BuyModal.scss';
import Step1 from './components/Step1/Step1';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import Modal from '../../../../components/ui/Modal/Modal';
import logotype from './images/logotype_dark.png';

type SellModalProps = {
    onClose: (isSuccess: boolean) => void;
}

const BuyModal: React.FC<SellModalProps> = (props) => {
    const {i18n} = useContext(i18nContext);
    useEffect(() => {
        window.onbeforeunload = () => i18n.t('messages.unsavedDataWarning');
    }, [i18n]);
    const handleCloseModal = () => {
        const isClose = window.confirm(i18n.t('messages.unsavedDataWarning'));
        if (isClose) {
            window.onbeforeunload = () => {};
            props.onClose(false);
        }
        document.body.classList.remove('ReactModal__Body--open');

        const params = new URLSearchParams(window.location.search);
        const isNeedEvaluateModalOpen = params.get('buy') === 'true';
        if (isNeedEvaluateModalOpen) {
            window.location.href = '/';
        }
    }

    return (<Modal
        className={'ReactModal__Content-buy-modal'}
        isOpen={true}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
    >
        <div className="modal-header">
            <div className="row between-xs">
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1">
                </div>
                <div className="col-xs-8 col-sm-10 col-md-10 col-lg-10 ta-center real-estate__header">
                    <img className="real-estate__logotype" src={logotype} alt={'reselecta'} />
                    <div className="real-estate__subtitle">
                        {i18n.t('buyModal.header.subtitle')}
                    </div>
                </div>
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 ta-right">
                    <i className="icon-close" onClick={handleCloseModal}/>
                </div>
            </div>
        </div>
        <Step1 onClose={handleCloseModal} onSubmitSuccess={(isSuccess: boolean) => props.onClose(isSuccess)}/>
    </Modal>)
}
export default BuyModal;
