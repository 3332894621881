import React, {useContext} from 'react';
import './Calculator.scss';
import Button from '../ui/Button/Button';
import {i18nContext} from '../i18n/I18nProvider';
import {useOutletContext} from 'react-router-dom';

const Calculator: React.FC<{}> =() => {
    const {i18n} = useContext(i18nContext);
    const {setSellModalProps} = useOutletContext<any>();
    const handleOpenCalculator = () => {
        setSellModalProps({isOpen: true});
    }
    return (<section className="calculator">
        <div className="row center-xs center-sm center-md center-lg">
            <div className="col-xs-12 col-sm-12 col-sm-6 col-md-5 col-lg-5">
                <h2>
                    <span>{i18n.t('buy.calculator.title1')} </span>
                    {i18n.t('buy.calculator.title2')}
                </h2>
            </div>
        </div>
        <div className="row center-xs center-sm center-md center-lg">
            <div className="col-xs-12 col-sm-12 col-sm-6 col-md-4 col-lg-4">
                <p>
                    {i18n.t('buy.calculator.description')}
                </p>
            </div>
        </div>
        <div className="calculator-action">
            <Button onClick={handleOpenCalculator}>
                {i18n.t('buy.calculator.button')}
            </Button>
        </div>
    </section>);
}

export default Calculator;
