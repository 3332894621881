import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

const leadClearState: Lead = {
    province: 'Valencia',
    realEstateType: '',
    price: '',
    fullName: '',
    phone: '',
    phoneCode: '+34',
    email: '',
    description: '',
    notes: '',
    type: '',
    status: 'new'
}

class Store {
    lead: Lead = {...{}, ...leadClearState};
    constructor() {
        makeObservable(this, {
            lead: observable,
            merge: action,
            clear: action,
            get: action,
        });
    }

    get() {
        return this.lead;
    }

    async create(data: Lead) {
        try {
            return await api.request('POST', '/lead', data);
        } catch (err) {}
    }

    merge(data: any) {
        this.lead = {...this.lead, ...data};
    }

    clear() {
        this.lead = {...{}, ...leadClearState};
    }
}

export type Lead = {
    id?: string;
    province?: string;
    realEstateType?: string;
    price?: string;
    fullName: string;
    phone: string;
    phoneCode: string;
    email?: string;
    description?: string;
    notes?: string;
    type?: string;
    status: string;
    createdAt?: string;
}

export type LeadStore = {
    lead: Lead;
    get: () => Lead;
    create: (data: Lead) => Promise<Lead>;
    merge: (data: any) => void;
    clear: () => void;
}

export const leadStore = new Store();
export const leadStoreContext = createContext(leadStore);
