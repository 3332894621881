import React, {useContext, useRef, useState} from 'react';
import {RealEstateType, realEstateTypes} from '../../../../store/components/RealEstateStore';
import './Searcher2.scss';
import Popover from '../../../../components/ui/Popover/Popover';
import {useNavigate} from 'react-router-dom';
import {Province, provinceStoreContext} from '../../../../store/components/ProvinceStore';
import {i18nContext} from '../../../../components/i18n/I18nProvider';

type Searcher2Props = {}

type Price = {
    id: number,
    name: string;
    value: string;
}

const Searcher2: React.FC<Searcher2Props> = (props) => {
    const {locale, i18n} = useContext(i18nContext);
    const navigate = useNavigate();
    const provinceStoreCtx = useContext(provinceStoreContext);
    const [provinces] = useState(provinceStoreCtx.getProvinces());
    const [province, setProvince] = useState<Province>(provinceStoreCtx.getProvince('Valencia'));
    const provincePopoverRef = useRef<HTMLDivElement>(null);
    const [realEstateType, setRealEstateType] = useState<RealEstateType>({id: 0, name: 'All types', value: 'all', iconName: ''} as RealEstateType);
    const realEstateTypePopoverRef = useRef<HTMLDivElement>(null);
    const [price, setPrice] = useState<Price>({id: 0, name: 'Max price', value: ''} as Price);
    const prices: Price[] = [
        {id: 1, name: '500.000€', value: '500000'},
        {id: 2, name: '1.000.000€', value: '1000000'},
        {id: 3, name: '1.500.000€', value: '1500000'},
        {id: 4, name: '2.000.000€+', value: '2000000'}
    ]
    const pricePopoverRef = useRef<HTMLDivElement>(null);
    const handleSelectProvince = (province: Province) => {
        setProvince(province);
        // @ts-ignore
        setTimeout(() => provincePopoverRef.current && provincePopoverRef.current.close(), 50);
    }
    const handleSelectRealEstateType = (realEstateType: RealEstateType) => {
        setRealEstateType(realEstateType);
        // @ts-ignore
        setTimeout(() => realEstateTypePopoverRef.current && realEstateTypePopoverRef.current.close(), 50);
    }
    const handleSelectPrice = (price: Price) => {
        setPrice(price);
        // @ts-ignore
        setTimeout(() => pricePopoverRef.current && pricePopoverRef.current.close(), 50);
    }
    const handleNavigateToCatalog = () => {
        if ((realEstateType.id !== 0) && (price.id !== 0)) {
            navigate(`/buy?province=${province.name}&realEstateType=${realEstateType.value}&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=${price.value}`);
        } else if (realEstateType.id !== 0) {
            navigate(`/buy?province=${province.name}&realEstateType=${realEstateType.value}&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        } else if (price.id !== 0) {
            navigate(`/buy?province=${province.name}&realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=${price.value}`);
        } else {
            navigate(`/buy?province=${province.name}&realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        }
    }

    return (<div className="searcher">
        <div className="searcher__field">
            <Popover
                className="searcher__field-province-dropdown-wrapper"
                ref={provincePopoverRef}
                content={<div className="searcher__field-province-dropdown">
                    {provinces.map((p: Province) => {
                        return (<button key={p.name}
                            className={`searcher__field-dropdown-item ${province.name === p.name}`}
                            onClick={() => handleSelectProvince(p)}>
                            <div>
                                <i className="icon-building1" />
                                {p.name}
                            </div>
                        </button>)
                    })}
                </div>}>
                <div className="field-city-dropdown__trigger">
                    <div className="searcher__field-dropdown-placeholder">
                        <i className="icon-building1" />
                        <span>{province.name}</span>
                        <i className="icon-arrow" />
                    </div>
                </div>
            </Popover>
            <Popover
                className="searcher__field-re-dropdown-wrapper"
                ref={realEstateTypePopoverRef}
                content={<div className="searcher__field-re-dropdown">
                    <button key={'0_all_real-estate-types'}
                            className={`searcher__field-dropdown-item searcher__field-dropdown-item--default`}
                            onClick={() => handleSelectRealEstateType({id: 0, name: i18n.t('buy.searcher.realEstateTypePlaceholder'), value: 'all', iconName: ''})}>
                        <div>
                            {i18n.t('buy.searcher.realEstateTypePlaceholder')}
                        </div>
                    </button>
                    {realEstateTypes.map((re: RealEstateType) => {
                        return (<button key={re.id + '_' + re.name}
                                        className={`searcher__field-dropdown-item ${realEstateType.name === re.name}`}
                                        onClick={() => handleSelectRealEstateType(re)}>
                            <div>
                                {i18n.t(`realEstateTypes.${re.value}`)}
                            </div>
                        </button>)
                    })}
                </div>}>
                <div className="field-city-dropdown__trigger">
                    <div className="searcher__field-dropdown-placeholder">
                        <span>{realEstateType.id === 0 ? i18n.t(`buy.searcher.${realEstateType.name}`) : i18n.t(`realEstateTypes.${realEstateType.value}`)}</span>
                        <i className="icon-arrow" />
                    </div>
                </div>
            </Popover>
            <Popover
                className="searcher__field-price-dropdown-wrapper"
                ref={pricePopoverRef}
                content={<div className="searcher__field-price-dropdown">
                    <button key={'0_max_price'}
                            className={`searcher__field-dropdown-item searcher__field-dropdown-item--default`}
                            onClick={() => handleSelectPrice({id: 0, name: i18n.t('buy.searcher.pricePlaceholder'), value: ''})}>
                        <div>
                            {i18n.t('buy.searcher.pricePlaceholder')}
                        </div>
                    </button>
                    {prices.map((p: Price) => {
                        return (<button key={p.id + '_' + p.name}
                                        className={`searcher__field-dropdown-item ${price.name === p.name}`}
                                        onClick={() => handleSelectPrice(p)}>
                            <div>
                                {p.name}
                            </div>
                        </button>)
                    })}
                </div>}>
                <div className="field-city-dropdown__trigger">
                    <div className="searcher__field-dropdown-placeholder">
                        <span>{price.id === 0 ? i18n.t(`buy.searcher.${price.name}`) : price.name}</span>
                        <i className="icon-arrow" />
                    </div>
                </div>
            </Popover>
            <div className="searcher__field-button" onClick={handleNavigateToCatalog}>
                {i18n.t('buy.searcher.button')}
            </div>
        </div>
    </div>)
}

export default Searcher2;
