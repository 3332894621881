import React, {useContext, useEffect, useState} from 'react';
import './Step1.scss';
import {observer} from 'mobx-react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import Select from '../../../../../../components/ui/Select/Select';
import Button from '../../../../../../components/ui/Button/Button';
import {Province, provinceStoreContext} from '../../../../../../store/components/ProvinceStore';
import FieldText from '../../../../../../components/ui/FieldText/FieldText';
import FieldPhone from '../../../../../../components/ui/FieldPhone/FieldPhone';
import {Lead, leadStoreContext} from '../../../../../../store/components/LeadStore';
import Checkbox from '../../../../../../components/ui/Checkbox/Checkbox';
import {Link} from 'react-router-dom';

type Step1Props = {
    onSubmitSuccess: (isSuccess: boolean) => void;
    onClose: () => void;
}

const Step1: React.FC<Step1Props> = observer((props) => {
    const {i18n} = useContext(i18nContext);
    const provinceStoreCtx = useContext(provinceStoreContext);
    const leadStoreCtx = useContext(leadStoreContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {trigger, setValue, handleSubmit} = methods;
    const [isChecked, setChecked] = useState(true);
    const [isRequest, setRequest] = useState(false);
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());
    const [province, setProvince] = useState(provinceStoreCtx.getProvince(leadStoreCtx.lead.province ?? 'Valencia'));
    useEffect(() => {
        const province = provinceStoreCtx.getProvince(leadStoreCtx.lead.province ?? 'Valencia');
        setProvince(province);
        setValue('province', province.name);
    }, [leadStoreCtx.lead.province, provinceStoreCtx, setValue]);
    const handleChangeProvince = (province: Province) => {
        leadStoreCtx.merge({province: province.name});
    }

    const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        leadStoreCtx.merge({[fieldName]: event.target.value});
        if (fieldName === 'phone') {
            methods.setValue('phone2', event.target.value);
            methods.trigger('phone2').then(() => {});
        }
    }
    const onSubmit: SubmitHandler<Inputs> = () => {
        const data: Lead = JSON.parse(JSON.stringify(leadStoreCtx.get()));
        let key: string;
        for (key in data) {
            // @ts-ignore
            if (data[key] === '') {
                // @ts-ignore
                data[key] = null;
            }
        }
        data.type = 'seller';
        setRequest(true);
        leadStoreCtx.create(data)
            .then((result) => {
                if (result) {
                    props.onSubmitSuccess(true);
                }
            }).finally(() => setRequest(false))
    }

    const handleChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setValue('privacy', event.target.checked)
        trigger('privacy').then(() => {});
    }

    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content modal-content-step1">
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 ta-center real-estate__description">
                        {i18n.t('sellModal.description')}
                    </div>
                </div>
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12">
                        <Select
                            prefixTemplate={() => <i className="icon-building1"/>}
                            items={provinces}
                            initBy={province}
                            textKey={'name'}
                            registerProps={{name: 'province', options: {required: true}}}
                            callback={handleChangeProvince}
                            defaultText={i18n.t('sellModal.provincePlaceholder')}
                        />
                    </div>
                    <div className="col-xs-12">
                        <FieldText
                            placeholder={i18n.t('sellModal.fullNamePlaceholder')}
                            value={leadStoreCtx.lead.fullName ?? ''}
                            registerProps={{name: 'fullName', options: {required: true, onChange: handleChangeFieldText}}}
                        />
                    </div>
                    <div className="col-xs-12">
                        <input type="hidden" {...methods.register('phone2', {
                            required: true,
                            onChange: handleChangeFieldText
                        })}/>
                        <FieldPhone
                            type="tel"
                            placeholder={'_________'}
                            className={`field-phone ${methods && methods.formState.errors && methods.formState.errors.phone2 ? 'field-phone--invalid' : ''}`}
                            value={leadStoreCtx.lead.phone ?? ''}
                            onChange={handleChangeFieldText}
                            name={'phone'}
                            maxLength={9}
                            phoneCode={leadStoreCtx.lead.phoneCode ?? ''}
                            onChangePhoneCode={(phoneCode) => leadStoreCtx.merge({phoneCode})}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-actions">
                <div className="row center-xs">
                    <div className="col-xs-12 privacy">
                        <Checkbox
                            onChange={handleChangePrivacy}
                            registerProps={{name: 'privacy', options: {required: true, onChange: handleChangePrivacy}}}
                            checked={isChecked}>
                            {i18n.t('sellModal.termOfUseCheckbox.text1')}
                        </Checkbox>
                        <Link target="_blank" to={'/terms-of-use'} >{i18n.t('sellModal.termOfUseCheckbox.text2')}</Link>
                    </div>
                    <div className="col-xs-12 ta-center">
                        <Button disabled={isRequest} className="button" type="submit">{i18n.t('sellModal.actions.submit')}</Button>
                        <Button className="button button--light" type="button" onClick={props.onClose}>{i18n.t('sellModal.actions.close')}</Button>
                    </div>
                </div>
            </div>
        </form>
    </FormProvider>)
});

type Inputs = {
    province: string,
    fullName: string;
    phone: string;
    phone2: string;
    privacy: boolean;
};

export default Step1;
