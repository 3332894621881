import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

const realEstateClearState: RealEstate = {
    privacy: true,
    municipality: '',
    province: 'Valencia',
    country: 'Spain',
    street: '',
    postcode: '',
    realEstateType: '',
    presentationAddress: '',
    houseNumber: '',
    ladder: '',
    floor: '',
    door: '',
    cadastralNumber: '',
    livingArea: null,
    plotArea: null,
    bathroomCount: '',
    bedroomCount: '',
    buildingYear: null,
    condition: '',
    energyCertificate: null,
    energyClass: null,
    extras: [],
    requestType: 'call',
    fullName: '',
    email: '',
    phone: '',
    phoneCode: '+34',
    requestDate: '',
    requestTimeStart: '',
    requestTimeEnd: '',
    description: '',
    status: 'new',
}

const realEstateFilter: RealEstateFilter = {
    realEstateType: '',
    municipality: '',
    province: '',
    bedroomCountFrom: '',
    bedroomCountTo: '',
    livingAreaFrom: '',
    livingAreaTo: '',
    catalogPriceFrom: '',
    catalogPriceTo: '',
}

class Store {
    realEstateExtras: RealEstateExtra[] = [];
    realEstate: RealEstate = {...{}, ...realEstateClearState};
    isCatalogLoading = false;
    catalogFilter = {...{}, ...realEstateFilter};
    catalogRealEstates: RealEstate[] = [];
    isCatalogRealEstateLoading = false;
    constructor() {
        makeObservable(this, {
            realEstateExtras: observable,
            realEstate: observable,
            catalogFilter: observable,
            catalogRealEstates: observable,
            isCatalogLoading: observable,
            isCatalogRealEstateLoading: observable,
            mergeRealState: action,
            clearRealEstate: action,
            setRealEstateExtras: action,
            getRealEstateExtras: action,
            getStreets: action,
            loadRealEstateExtras: action,
            getRealEstate: action,
            loadRealEstate: action,
            mergeCatalogFilter: action,
            setCatalogLoading: action,
            setCatalogRealEstates: action,
            setRealEstateLoading: action,
        });
    }

    setRealEstateExtras(extras: RealEstateExtra[]) {
        this.realEstateExtras = extras;
    }

    getRealEstateExtras() {
        return this.realEstateExtras;
    }

    getRealEstate() {
        return this.realEstate;
    }

    async getStreets(street: string, type: string, signal: AbortSignal) {
        try {
            const result: Place[] = await api.request('GET', `/place/search?search=${street}&type=${type}`, null, signal);
            return result;
        } catch (err) {}
    }

    async loadRealEstateExtras() {
        try {
            const realEstateExtras = await api.request('GET', '/realEstate/extras');
            this.setRealEstateExtras(realEstateExtras);
            return realEstateExtras;
        } catch (err) {}
    }

    async loadRealEstates(params: RealEstateFilter) {
        try {
            if (params.catalogPriceFrom) {
                params.catalogPriceFrom = params.catalogPriceFrom.replace(/\./gi, '');
            }
            if (params.catalogPriceTo) {
                params.catalogPriceTo = params.catalogPriceTo.replace(/\./gi, '');
            }
            this.setCatalogLoading(true);
            const realEstates = await api.request('GET', '/realEstates/catalog?' + new URLSearchParams(params).toString());
            this.setCatalogRealEstates(realEstates);
            this.setCatalogLoading(false);
        } catch (err) {}
    }

    async loadRealEstate(id: string) {
        try {
            this.setRealEstateLoading(true);
            const realEstate = await api.request('GET', `/realEstate/${id}/details`);
            this.setRealEstateLoading(false);
            return realEstate;
        } catch (err) {
            this.setRealEstateLoading(false);
            return Promise.reject(null);
        }
    }

    async loadBestRealEstates() {
        try {
            return await api.request('GET', `/realEstates/bestOffers`);
        } catch (err) {
            return Promise.reject([]);
        }
    }

    setRealEstateLoading(isLoading: boolean) {
        this.isCatalogRealEstateLoading = isLoading;
    }

    setCatalogLoading(isLoading: boolean) {
        this.isCatalogLoading = isLoading;
    }

    setCatalogRealEstates(realEstates: RealEstate[]) {
        this.catalogRealEstates = realEstates;
    }

    getRealEstates() {
        return this.catalogRealEstates;
    }

    mergeRealState(data: any) {
        this.realEstate = {...this.realEstate, ...data};
    }

    clearRealEstate() {
        this.realEstate = {...{}, ...realEstateClearState};
    }

    mergeCatalogFilter(data: any) {
        this.catalogFilter = {...this.catalogFilter, ...data};
    }

    clearCatalogFilter() {
        this.catalogFilter = {...{}, ...realEstateFilter}
    }
}

export type RealEstate = {
    id?: string;
    privacy: boolean;
    municipality: string;
    province: string;
    country: string;
    street: string;
    postcode: string;
    realEstateType: string;
    presentationAddress: string;
    presentationManualAddress?: string;
    houseNumber: string;
    ladder: string | null;
    floor: string;
    door: string;
    cadastralNumber: string | null;
    livingArea: number | null;
    plotArea: number | null;
    bathroomCount: string;
    bedroomCount: string;
    buildingYear: number | null;
    condition: string;
    energyCertificate: boolean | null;
    energyClass: string | null;
    extras: number[];
    requestType: string;
    fullName?: string;
    email?: string;
    phone?: string;
    phoneCode?: string;
    requestDate?: string;
    requestTimeStart?: string;
    requestTimeEnd?: string;
    description?: string;
    status: string;
    realEstateMarketingName?: string | null;
    realEstateMarketingNameES?: string | null;
    realEstateMarketingDescription?: string | null;
    realEstateMarketingDescriptionES?: string | null;
    price?: number | null;
    longitude?: number | null;
    latitude?: number | null;
    previews?: RealEstatePreview[];
}

export type RealEstatePreview = {
    imagePath: string;
    imageUrl: string;
}

export type RealEstateExtra = {
    id: number;
    name: string;
}

export type Place = {
    presentationAddress: string;
    street?: string;
    city: string;
    country: string;
}

export type RealEstateStore = {
    realEstateExtras: RealEstateExtra[];
    realEstate: RealEstate;
}

export type SearchAddress = {
    country: string;
    city: string;
    street?: string;
    presentationAddress: string;
}

export type Condition = {
    id: number;
    name: string;
    value: string
}

export const conditions: Condition[] = [
    {id: 1, name: 'New', value: 'new'},
    {id: 2, name: 'Good condition', value: 'good condition'},
    {id: 3, name: 'Originally', value: 'originally'},
    {id: 4, name: 'Renovated', value: 'renovated'},
    {id: 5, name: 'To reform', value: 'to reform'},
]

export const realEstateTypes: RealEstateType[] = [
    {id: 1, name: 'Residential', value: 'residential', iconName: 'icon-home'},
    {id: 2, name: 'Commercial', value: 'commercial', iconName: 'icon-building'},
]

export type RealEstateType = {
    id: number;
    name: string;
    value: string;
    iconName: string;
}

export type RealEstateFilter = {
    realEstateType: string,
    municipality: string,
    province: string,
    bedroomCountFrom: string,
    bedroomCountTo: string,
    livingAreaFrom: string,
    livingAreaTo: string,
    catalogPriceFrom: string,
    catalogPriceTo: string,
}

export type Municipality = {
    id: number;
    name: string;
    state_id: number;
    state_code: string;
    state_name: string,
    country_id: number,
    country_code: string,
    country_name: string,
    latitude: string,
    longitude: string,
    wikiDataId: string,
    meta: {
        revision: number,
        created: number,
        version: number
    },
    $loki: number
}

export type Street = {
    presentationAddress: string;
    street: string;
    postcode: string;
    district: string;
    area: string;
    municipality: string;
}

export const realEstateStore = new Store();
export const realEstateStoreContext = createContext(realEstateStore);
