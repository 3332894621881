import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import Modal from '../../../../components/ui/Modal/Modal';
import './ContactUSModal.scss';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import ContactForm from '../../../../components/ContactForm/ContactForm';

type FeedbackModalProps = {
    onClose: (isSuccess: boolean) => void;
    type: string;
}

const ContactUSModal: React.FC<FeedbackModalProps> = observer((props) => {
    const {i18n} = useContext(i18nContext);

    const handleCloseModal = () => {
        props.onClose(false);
        document.body.classList.remove('ReactModal__Body--open');
    }

    return (<Modal
        className={'ReactModal__Content-feedback'}
        isOpen={true}
        onRequestClose={handleCloseModal}
    >
        <div className="modal-header">
            <div className="row between-xs">
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 ta-right"></div>
                <div className="col-xs-8 col-sm-10 col-md-10 col-lg-10 ta-center">
                    {i18n.t('feedbackModal.header.title')}
                </div>
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 ta-right">
                    <i className="icon-close" onClick={handleCloseModal}/>
                </div>
            </div>
        </div>
        <ContactForm type={props.type} onClose={props.onClose} />
    </Modal>);
});

export default ContactUSModal;
