import React, {useContext, useEffect} from 'react';
import './Sell.scss';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useOutletContext} from 'react-router-dom';
import Headline from '../../components/ui/Headline/Headline';
import Calculator from '../../components/Calculator/Calculator';
import Step1 from '../Root/components/SellModal/components/Step1/Step1';
import {realEstateStoreContext} from '../../store/components/RealEstateStore';
import logotype from '../Root/components/SellModal/images/logotype_dark.png';
import {leadStoreContext} from '../../store/components/LeadStore';

const Sell: React.FC<any> = (props) => {
    const {i18n} = useContext(i18nContext);
    const {setSellModalProps, setSuccessModalProps} = useOutletContext<any>();
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const leadStoreCtx = useContext(leadStoreContext);
    useEffect(() => {
        realEstateStoreCtx.loadRealEstateExtras().then(() => {});
    }, [realEstateStoreCtx]);
    useEffect(() => {
        window.onbeforeunload = () => i18n.t('messages.unsavedDataWarning');
    }, [i18n]);

    const handleCloseRealEstateModal = (isSuccess: boolean) => {
        leadStoreCtx.clear();
        setSellModalProps({isOpen: false});
        if (isSuccess) {
            setSuccessModalProps({isOpen: true});
        }
    }

    return (<>
        <div className="sell">
            <Headline>Sell</Headline>
            <div className="sell-miniature sell-miniature-top"></div>
            <div className="sell-miniature sell-miniature-middle"></div>
            <div className="sell-miniature sell-miniature-triangle"></div>
            <section className="sell-section sell-section-arrow-right">
                <div className="sell-section__counter">
                    01
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.1.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.1.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-middle sell-section-arrow-right sell-form">
                <div className="sell-form__header">
                    <div className="row between-xs">
                        <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1">
                        </div>
                        <div className="col-xs-8 col-sm-10 col-md-10 col-lg-10 ta-center real-estate__header">
                            <img className="real-estate__logotype" src={logotype} alt={'reselecta'} />
                            <div className="real-estate__subtitle">
                                {i18n.t('sellModal.header.subtitle')}
                            </div>
                        </div>
                        <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 ta-right">
                        </div>
                    </div>
                </div>
                <Step1 onClose={() => {}} onSubmitSuccess={(isSuccess: boolean) => handleCloseRealEstateModal(isSuccess)}/>
            </section>

            <section className="sell-section sell-section-right sell-section-arrow-left">
                <div className="sell-section__counter">
                    02
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.2.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.2.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-middle sell-section-arrow-left">
                <div className="sell-section__counter">
                    03
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.3.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.3.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-arrow-right">
                <div className="sell-section__counter">
                    04
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.4.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.4.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-middle sell-section-arrow-right">
                <div className="sell-section__counter">
                    05
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.5.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.5.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-right sell-section-arrow-left">
                <div className="sell-section__counter">
                    06
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.6.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.6.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-middle">
                <div className="sell-section__counter">
                    07
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.7.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.7.description')}
                    </div>
                </div>
            </section>
        </div>

        <Calculator />
    </>);
};

export default Sell;
