import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {realEstateStore, RealEstateStore} from './components/RealEstateStore';
import {provinceStore, ProvinceStore} from './components/ProvinceStore';
import {leadStore, LeadStore} from './components/LeadStore';

class Store {
    realEstateStore: RealEstateStore = realEstateStore;
    provinceStore: ProvinceStore = provinceStore;
    leadStore: LeadStore = leadStore;
    isLoading: boolean = true;
    constructor() {
        makeObservable(this, {
            realEstateStore: observable,
            provinceStore: observable,
            leadStore: observable,
            isLoading: observable,
            setLoading: action,
        });

        this.provinceStore.get().then(() => this.setLoading(false));
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
}

export const store = new Store();
export const storeContext = createContext(store);
